import { Stack, Typography } from '@mui/material';

import { FC } from 'react';
import { Uris } from 'Uris';
import classes from './NotFoundPage.module.scss';

export const NotFoundPage: FC = () => {
  return (
    <Stack className={classes.root}>
      <Stack className={classes.container} spacing={5} alignItems='center'>
        <img className={classes.cover} alt='cover' src={Uris.Public.Image.NotFound.Cover} />
        <Typography variant='h3'>404 Oops, something went wrong!</Typography>
        <Typography variant='h6'>if the problem persist, please contact us</Typography>
      </Stack>
    </Stack>
  );
};
