import { TypographyOptions } from '@mui/material/styles/createTypography';

export const h1 = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '3.563rem', // 57px
  lineHeight: 1.123, // 64px / fontSize
};
export const h2 = {
  fontWeight: 500,
  fontSize: '2.813rem', // 45px
  lineHeight: 1.156, // 52px / fontSize
};
export const h3 = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '2.25rem', // 36px
  lineHeight: 1.222, // 44px / fontSize
};
export const h4 = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '2rem', // 32px
  lineHeight: 1.25, // 40px / fontSize
};
export const h5 = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '1.75rem', // 28px
  lineHeight: 1.286, // 36px / fontSize
};
export const h6 = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '1.5rem', // 24px
  lineHeight: 1.333, // 32px / fontSize
};
export const subtitle1 = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '1rem', // 16px
  lineHeight: 1.5, // 24px / fontSize
  letterSpacing: 1.5,
};
export const subtitle2 = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '0.875rem', // 14px
  lineHeight: 1.429, // 20px / fontSize
  letterSpacing: '0.1px',
};
export const label1 = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '0.875rem', // 14px
  lineHeight: 1.429, // 20px / fontSize
  letterSpacing: '0.1px',
};
export const label2 = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '0.688rem', // 11px
  lineHeight: 1.455, // 16px / fontSize
  letterSpacing: '0.5px',
};
export const body1 = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '1rem', // 16px
  lineHeight: 1.5, // 24px / fontSize
  letterSpacing: '0.5px',
};
export const body2 = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '0.75rem', // 12px
  lineHeight: 1.333, // 16px / fontSize
  letterSpacing: '0.4px',
};
export const typography: TypographyOptions = {
  fontFamily: 'Poppins',
  h1: h1,
  h2: h2,
  h3: h3,
  h4: h4,
  h5: h5,
  h6: h6,
  subtitle1: subtitle1,
  subtitle2: subtitle2,
  label1,
  label2,
  body1: body1,
  body2: body2,
};
