import baseX from 'base-x';
import { datetimeFormatter } from 'common/formatters';

const BASE_16_CHARSET = '0123456789abcdef';
const BASE_36_CHARSET = '0123456789abcdefghijklmnopqrstuvwxyz';
const base16 = baseX(BASE_16_CHARSET);
const base36 = baseX(BASE_36_CHARSET);

export const validateEmail = (email: string) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(email);
};

export const validatePassword = (password: string) => {
  // minimum length is 6
  // at least 1 character
  // at least 1 digit
  return /^(?=.*[a-zA-Z])(?=.*\d).{6,}$/.test(password);
};

export const validateAddress = (address: string) => {
  return /^0x[a-fA-F0-9]{40}$/.test(address);
};

export const validateUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export const dedupArray = (a: string[]) => Array.from(new Set(a));

export const replaceArrayIdxWithValue = <T>(arr: T[], idx: number, value: T) => {
  if (idx < 0 || idx >= arr.length) throw Error(`index ${idx} out of array range [0, ${arr.length - 1}]`);
  return [...arr.slice(0, idx), value, ...arr.slice(idx + 1)];
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return (parts.pop() || '').split(';').shift();
};

export const setCookie = (name: string, value: string, days: number = 7) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const downloadAsCsv = (data: any[], filename = 'data.csv') => {
  const columnHeaders = Object.keys(data[0]);
  const csvData = [
    columnHeaders,
    ...data.map((d) =>
      columnHeaders.map((header) => {
        switch (typeof d[header]) {
          // for object type -> need to do some operation
          case 'number':
            if (header.endsWith('_at')) {
              return datetimeFormatter.format(d[header] * 1000);
            }
            return d[header];
          case 'string':
            return `"${d[header].replaceAll('"', "'")}"`;
          case 'object':
            // serialize and escape double quote, then enclose string with ""
            return `"${JSON.stringify(d[header]).replaceAll('"', '""')}"`;
          default:
            return d[header];
        }
      }),
    ),
  ]
    .map((row) => row.join(','))
    .join('\n');
  const blob = new Blob([csvData], { type: 'text/csv' });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
};

export const fileSizeToString = (bytes: number, decimals = 2, base = 1000) => {
  if (bytes === 0) return '0 Bytes';

  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(base));

  return parseFloat((bytes / Math.pow(base, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const hexToBase36 = (hex: string) => {
  return base36.encode(base16.decode(hex));
};

export const getGAClientId = () => {
  const gaCookie = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
  const rawClientId = gaCookie ? decodeURIComponent(gaCookie[1]) : null;
  let clientId = null;
  if (rawClientId) clientId = rawClientId.match(/(\d+\.\d+)$/);
  return clientId ? 'c_' + clientId[1] : null;
};

export const getHomepageUrl = (path: string) => {
  const isDev = window.location.hostname === 'localhost';
  return path.replace(
    ':homepage',
    isDev ? window.location.origin.replace('3000', '3001') : window.location.origin.replace('accounts.', ''),
  );
};
