import { Components, Paper, Theme } from '@mui/material';
import {
  colorError40,
  colorPrimary40,
  colorPrimary80,
  colorSurface1,
  colorSurface2,
  colorSurface4,
  colorSurface5,
} from 'common/params';

import { label1 } from 'theme/typography';

// common component style
const components: Components<Omit<Theme, 'components'>> = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'unset',
        borderRadius: '50vh',
        '&.MuiButton-sizeMedium': {
          height: '40px',
        },
      },
    },
  },
  MuiTableContainer: {
    defaultProps: {
      // @ts-ignore
      component: Paper,
    },
    styleOverrides: {
      root: { borderRadius: '28px' },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: { '&:hover': { background: colorSurface2 }, '&.Mui-selected': { backgroundColor: colorSurface5 } },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        background: colorSurface1,
        borderWidth: '1px',
        ...label1,
      },
      root: { borderWidth: 0 },
    },
  },
  MuiTablePagination: {
    defaultProps: {
      // @ts-ignore
      component: 'div',
    },
    styleOverrides: {
      root: {
        background: colorSurface1,
      },
      spacer: { display: 'none' },
      displayedRows: { flex: 1, textAlign: 'right' },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: { background: colorSurface2 },
    },
  },
  MuiFormControl: { styleOverrides: { root: { color: 'inherit' } }, defaultProps: { margin: 'dense' } },
  MuiFormControlLabel: { styleOverrides: { root: { color: 'inherit' } } },
  MuiFormLabel: { styleOverrides: { root: { color: 'inherit' } } },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        color: 'inherit',
        backgroundColor: 'transparent',
        '&.Mui-error': {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colorError40, // change default black border to error color
          },
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: colorPrimary40, // change default black border to primary color
        },
        '&.Mui-disabled': {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.28)',
          },
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'unset',
        '&.Mui-selected': {
          backgroundColor: colorSurface4,
        },
      },
    },
  },
};

// overwrite some color style based on light theme
export const lightComponents: Components<Omit<Theme, 'components'>> = {
  ...components,
};

// overwrite some color style based on dark theme
export const darkComponents: Components<Omit<Theme, 'components'>> = {
  ...components,
  MuiPopover: {
    styleOverrides: {
      paper: { background: 'auto' },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        color: colorPrimary80,
      },
      iconOutlined: {
        color: colorPrimary80,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        color: colorPrimary80,
      },
    },
  },
};
