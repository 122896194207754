import { ContextProvider } from 'contexts/ContextProvider';
import { MainPage } from 'pages/MainPage';
import { MessageProvider } from 'components/message/MessageProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { TrackingProvider } from 'components/tracking/TrackingProvider';
import { Web3Provider } from 'components/web3/Web3Provider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ContextProvider>
      <TrackingProvider>
        <MessageProvider>
          <Web3Provider>
            <MainPage />
          </Web3Provider>
        </MessageProvider>
      </TrackingProvider>
    </ContextProvider>
  </React.StrictMode>,
);
