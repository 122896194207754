import { Config } from 'config';

export const Uris = Object.seal({
  Api: {
    Auth: {
      EmailExists: `${Config.ApiServer}/auth/email/:email`,
      Oauth: `${Config.ApiServer}/auth/oauth`,
      SignIn: `${Config.ApiServer}/auth/sign_in`,
      SignUp: `${Config.ApiServer}/auth/sign_up`,
      SignOut: `${Config.ApiServer}/auth/sign_out`,
      SignInWithAddress: `${Config.ApiServer}/auth/sign_in_address`,
      VerifySignature: `${Config.ApiServer}/auth/verify_signature`,
      Register: `${Config.ApiServer}/auth/register`,
      RegisterConfirm: `${Config.ApiServer}/auth/register_confirm`,
      CreateResetPasswordToken: `${Config.ApiServer}/auth/reset_password_token`,
      ResetPassword: `${Config.ApiServer}/auth/reset_password`,
    },
    User: {
      Profile: `${Config.ApiServer}/users/profile`,
    },
  },
  Pages: {
    Root: '/',
    Auth: {
      Login: '/login',
      Logout: 'logout',
      SignIn: '/sign_in',
      SignUp: '/sign_up',
      RegisterConfirm: '/register_confirm/:accountId',
      ForgetPassword: '/forget_password',
      ResetPassword: '/reset_password/:token',
      UserDisabled: '/user_disabled',
    },
  },
  Public: {
    Logo: {
      NoSub: {
        Light: '/growing3-logo-light-no-sub.svg',
        Dark: '/growing3-logo-dark-no-sub.svg',
      },
    },
    Image: {
      NotFound: {
        Cover: '/images/not_found/cover.png',
      },
      Auth: {
        Login: '/images/auth/login.png',
        Promo: '/images/auth/promo.png',
        ForgetPassword: '/images/auth/forget-password.png',
      },
    },
  },

  External: {
    Home: ':homepage',
    TermsOfService: ':homepage/terms-of-use',
    PrivacyPolicy: ':homepage/privacy',
  },
});

const UriTitleMap = new Map<string, string>([
  [Uris.Pages.Root, 'Growing3'],
  [Uris.Pages.Auth.Login, 'Growing3 | Login'],
  [Uris.Pages.Auth.Logout, 'Growing3 | Logout'],
  [Uris.Pages.Auth.SignIn, 'Growing3 | Sign In'],
  [Uris.Pages.Auth.SignUp, 'Growing3 | Sign Up'],
  [Uris.Pages.Auth.RegisterConfirm, 'Growing3 | Register Confirm'],
  [Uris.Pages.Auth.ForgetPassword, 'Growing3 | Forget Password'],
  [Uris.Pages.Auth.ResetPassword, 'Growing3 | Reset Password'],
  [Uris.Pages.Auth.UserDisabled, 'Growing3 | User Disabled'],
]);

export const getUrlTitle = (pathname: string) => {
  for (const [pattern, title] of Array.from(UriTitleMap.entries())) {
    const regexPattern = pattern
      .replace(/:\w+/g, '[\\d\\w-]+') // Match digits, words, and hyphens
      .replace(/\//g, '\\/') // Escape forward slashes
      .replace(/\./g, '\\.'); // Escape dots
    const regex = new RegExp(`^${regexPattern}$`);
    if (regex.test(pathname)) {
      return title;
    }
  }
  return undefined; // Return undefined if no match is found
};
