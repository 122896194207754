import { FC, HTMLAttributes } from 'react';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { arbitrum, avalanche, bsc, gnosis, mainnet, optimism, polygon } from 'wagmi/chains';

import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { publicProvider } from 'wagmi/providers/public';

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [arbitrum, avalanche, bsc, gnosis, mainnet, optimism, polygon],
  [publicProvider()],
);

const config = createConfig({
  autoConnect: true,
  // we could add more connectors here
  connectors: [new MetaMaskConnector({ chains })],
  publicClient,
  webSocketPublicClient,
});

export const Web3Provider: FC<HTMLAttributes<HTMLElement>> = (props) => {
  return <WagmiConfig config={config}>{props.children}</WagmiConfig>;
};
