// NOTE: Please keep in sync with `styles/global.scss`

/* Colors */
export const colorPrimary0 = '#000000';
export const colorPrimary10 = '#0a2c65';
export const colorPrimary20 = '#0d46a1';
export const colorPrimary30 = '#1564c0';
export const colorPrimary40 = '#1976d2';
export const colorPrimary50 = '#1e87e5';
export const colorPrimary60 = '#2095f3';
export const colorPrimary70 = '#42a5f5';
export const colorPrimary80 = '#6ab8f7';
export const colorPrimary90 = '#90caf9';
export const colorPrimary95 = '#bbdefb';
export const colorPrimary99 = '#fafdff';
export const colorPrimary100 = '#ffffff';

export const colorSecondary0 = '#000000';
export const colorSecondary10 = '#19212b';
export const colorSecondary20 = '#21497c';
export const colorSecondary30 = '#376596';
export const colorSecondary40 = '#3f75a8';
export const colorSecondary50 = '#4986ba';
export const colorSecondary60 = '#5393c6';
export const colorSecondary70 = '#64a2cd';
export const colorSecondary80 = '#7ab2d5';
export const colorSecondary90 = '#9bc7e2';
export const colorSecondary95 = '#c0dced';
export const colorSecondary99 = '#fafdff';
export const colorSecondary100 = '#ffffff';

export const colorTertiary0 = '#000000';
export const colorTertiary10 = '#006838';
export const colorTertiary20 = '#008f4d';
export const colorTertiary30 = '#00b266';
export const colorTertiary40 = '#00c473';
export const colorTertiary50 = '#00d882';
export const colorTertiary60 = '#00ea8f';
export const colorTertiary70 = '#00f1a1';
export const colorTertiary80 = '#00f6b4';
export const colorTertiary90 = '#82f9cb';
export const colorTertiary95 = '#b9fbdf';
export const colorTertiary99 = '#e2fef2';
export const colorTertiary100 = '#ffffff';

export const colorError0 = '#000000';
export const colorError10 = '#410e0b';
export const colorError20 = '#601410';
export const colorError30 = '#8c1d18';
export const colorError40 = '#b3261e';
export const colorError50 = '#dc362e';
export const colorError60 = '#e46962';
export const colorError70 = '#ec928e';
export const colorError80 = '#f2b8b5';
export const colorError90 = '#f9dedc';
export const colorError95 = '#fceeee';
export const colorError99 = '#fffbf9';
export const colorError100 = '#ffffff';

export const colorNeutral0 = '#000000';
export const colorNeutral10 = '#0d1012';
export const colorNeutral20 = '#1b2024';
export const colorNeutral30 = '#3c4146';
export const colorNeutral40 = '#5a6065';
export const colorNeutral50 = '#6e7479';
export const colorNeutral60 = '#979da2';
export const colorNeutral70 = '#b6bcc2';
export const colorNeutral80 = '#d9dfe5';
export const colorNeutral90 = '#e7eef3';
export const colorNeutral95 = '#eef5fb';
export const colorNeutral99 = '#fafdff';
export const colorNeutral100 = '#ffffff';

export const colorNeutralVariant0 = '#000000';
export const colorNeutralVariant10 = '#1b2631';
export const colorNeutralVariant20 = '#263444';
export const colorNeutralVariant30 = '#475c72';
export const colorNeutralVariant40 = '#57708a';
export const colorNeutralVariant50 = '#657e9b';
export const colorNeutralVariant60 = '#7c91ab';
export const colorNeutralVariant70 = '#92a5bd';
export const colorNeutralVariant80 = '#afbfd3';
export const colorNeutralVariant90 = '#dfe6ec';
export const colorNeutralVariant95 = '#eff5fa';
export const colorNeutralVariant99 = '#fafdff';
export const colorNeutralVariant100 = '#ffffff';

export const colorSurface = '#fafdff';
export const colorSurface1 = '#e7f2fc';
export const colorSurface2 = '#e2edfa';
export const colorSurface3 = '#dbe9f8';
export const colorSurface4 = '#d9e7f8';
export const colorSurface5 = '#d6e5f7';

export const colorDisabled = 'rgba(0, 0, 0, 0.38)';

/* Layout */
export const appBarHeight = '95px';
export const sideBarWidth = '100px';
export const userSideBarWidth = '320px';
export const panelWidth = '750px';
export const spacing = '8px';
export const spacingX2 = '16px';

/* Shadow */
export const boxShadowWhite1 = '0px 1px 8px rgba(62, 64, 66, 0.05)'; // white S
export const boxShadowWhite2 = '0px 4px 12px rgba(62, 64, 66, 0.09)'; // white M
export const boxShadowButton1 = '0px 8px 16px rgba(55, 41, 201, 0.16)'; // button colorPrimary
export const boxShadowButton2 = '0px 8px 16px rgba(151, 147, 199, 0.16)'; // button colorPrimary 95%
export const boxShadowButton3 = '0px 8px 16px rgba(158, 163, 168, 0.16)'; // button white
export const boxShadowButton4 = '0px 8px 16px rgba(62, 134, 145, 0.16)'; // button colorSecondary

/* border */
export const border = `0.5px solid ${colorNeutralVariant50}`;
export const borderDisabled = `0.5px solid ${colorDisabled}`;

/* border radius */
export const borderRadius = '8px';

/* chip like input height */
export const chipInputHeight = '32px';

export const passwordRules = ['* At least 1 letter', '* At least 1 digit', '* Be at least 6 characters'];
