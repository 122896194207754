import { FC, ReactNode } from 'react';

import { Stack } from '@mui/material';
import classes from './CommonChip.module.scss';

interface CommonChipProps {
  color?: string;
  backgroundColor?: string;
  children?: ReactNode;
}

export const CommonChip: FC<CommonChipProps> = ({ backgroundColor, color, children }) => {
  return (
    <Stack
      className={classes.chip}
      style={{ backgroundColor: backgroundColor || 'auto', color: color || 'auto', borderColor: color || 'auto' }}
    >
      <div className={classes.icon}>{children}</div>
    </Stack>
  );
};
