import { VariantType, useSnackbar } from 'notistack';

import { useCallback } from 'react';

export const useMessage = () => {
  /** Snackbar */
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showMessage = useCallback(
    (message: string, variant: VariantType = 'info') => {
      const key = enqueueSnackbar(message, {
        variant,
        onClick: () => closeSnackbar(key),
        style: { whiteSpace: 'pre-line' },
      });
    },
    [enqueueSnackbar, closeSnackbar],
  );

  return { showMessage };
};
