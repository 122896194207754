import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, Stack } from '@mui/material';
import { colorPrimary40, colorSurface1 } from 'common/params';

import { CloseOutlined } from '@mui/icons-material';
import { FC } from 'react';
import classes from './CommonDialog.module.scss';

interface CommonDialogProps extends DialogProps {
  mainContent?: React.ReactNode;
  cover?: React.ReactNode;
  footer?: React.ReactNode;
  onDialogClose?: () => void;
}

export const CommonDialog: FC<CommonDialogProps> = ({ cover, mainContent, footer, onDialogClose, ...props }) => {
  return (
    <Dialog
      className={classes.dialog}
      onClose={(_, reason) => {
        reason !== 'backdropClick' && onDialogClose?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onDialogClose}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      {cover ? cover : null}
      {mainContent ? <DialogContent className={classes.content}>{mainContent}</DialogContent> : null}
      {footer ? <DialogActions className={classes.actions}>{footer}</DialogActions> : null}
    </Dialog>
  );
};
