import { FC, useEffect } from 'react';

import ClientAPI from 'common/ClientAPI';
import { Spinner } from 'components/common/Spinner';
import { Uris } from 'Uris';
import { getHomepageUrl } from 'common/utils';
import { useAsync } from 'react-use';
import { useNavigate } from 'react-router-dom';

export const HomePage: FC = () => {
  const navigate = useNavigate();
  const { loading, value: user } = useAsync(async () => {
    return (await ClientAPI.getUserProfile()).data;
  }, []);

  useEffect(() => {
    if (loading) return;
    if (user) window.location.replace(getHomepageUrl(Uris.External.Home));
    else navigate(Uris.Pages.Auth.Login);
  }, [loading, user, navigate]);

  return <Spinner />;
};
