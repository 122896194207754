import { FC, HTMLAttributes, useContext, useEffect } from 'react';

import { Box } from '@mui/material';
import { Outlet } from 'react-router';
import { TrackingContext } from 'contexts/TrackingContext';
import classNames from 'classnames';
import classes from './LayoutProvider.module.scss';
import { useLocation } from 'react-use';
import { useTrackingInit } from 'common/hooks/useTrackingInit';

export const LayoutProvider: FC<HTMLAttributes<HTMLDivElement>> = () => {
  const { search } = useLocation();
  const { entryPoint$ } = useContext(TrackingContext);

  useEffect(() => {
    const parameters = new URLSearchParams(search);
    const entry_point = parameters.get('entry_point');
    if (entry_point) entryPoint$.next(entry_point);
  }, [entryPoint$, search]);

  useTrackingInit();

  /** Render */
  return (
    <Box className={classes.root}>
      <div className={classNames(classes.main)}>
        <Outlet />
      </div>
    </Box>
  );
};
