import { RegisterConfirmResp, SignInResp, SignOutResp, SignUpResp, VerifySignatureResp } from './types/Auth';

import { Uris } from 'Uris';
import { User } from 'common/types/User';
import { get } from 'lodash';

interface ApiResponse<T> {
  status?: string;
  data?: T;
  message?: string;
  total_rows?: number;
  return_rows?: number;
}

class ClientAPI {
  static async checkFetchResponse(res: Response): Promise<any | void> {
    let msg = '';
    try {
      const resJson = await res.json();
      const resJsonDetail = get(resJson, 'detail');
      if (resJsonDetail) msg = resJsonDetail;
      else {
        return resJson;
      }
    } finally {
      if (msg === '') {
        if (400 <= res.status && res.status < 500) {
          msg = `${res.status} Client Error: ${res.statusText} for url: ${res.url}`;
        } else if (500 <= res.status && res.status < 600) {
          // Server error
          msg = `${res.status} Server Error: ${res.statusText} for url: ${res.url}`;
        }
      }
    }

    if (msg !== '') {
      // send status code also for checking
      throw Error(msg, { cause: res.status });
    }
  }

  static extractResponse<T>(resJson: any): ApiResponse<T> {
    return {
      status: get(resJson, 'status', undefined),
      data: get(resJson, 'data', undefined),
      message: get(resJson, 'message', undefined),
      total_rows: get(resJson, 'total_rows', undefined),
      return_rows: get(resJson, 'return_rows', undefined),
    };
  }

  // return user profile
  public static async getUserProfile(): Promise<ApiResponse<User>> {
    const url = `${Uris.Api.User.Profile}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<User>(resJson);
  }

  public static async checkEmailExists(email: string) {
    const url = `${Uris.Api.Auth.EmailExists.replace(':email', email)}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<undefined>(resJson);
  }

  // return redirect_url
  public static async oauthSignIn(provider: string) {
    const url = `${Uris.Api.Auth.Oauth}/${provider}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string>(resJson);
  }

  public static async register(userLoginAuthId: string, email: string) {
    const url = `${Uris.Api.Auth.Register}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_login_auth_id: userLoginAuthId, email: email }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse(resJson);
  }

  public static async registerConfirm(userLoginAuthId: string, confirmCode: string) {
    const url = `${Uris.Api.Auth.RegisterConfirm}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_login_auth_id: userLoginAuthId, confirm_code: confirmCode }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<RegisterConfirmResp>(resJson);
  }

  // return sign up id
  public static async signUp(username: string, password: string) {
    const url = `${Uris.Api.Auth.SignUp}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username: username, password: password }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<SignUpResp>(resJson);
  }

  public static async signIn(username: string, password: string) {
    const url = `${Uris.Api.Auth.SignIn}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username: username, password: password }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<SignInResp>(resJson);
  }

  public static async signOut() {
    const url = `${Uris.Api.Auth.SignOut}`;
    const res = await fetch(url, { method: 'GET' });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<SignOutResp>(resJson);
  }

  // return sign message
  public static async signInWithAddress(provider: string, chain: string, address: string) {
    const url = `${Uris.Api.Auth.SignInWithAddress}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ provider, chain, address }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string>(resJson);
  }

  public static async verifyWeb3Signature(provider: string, chain: string, address: string, signature: string) {
    const url = `${Uris.Api.Auth.VerifySignature}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ provider, chain, address, signature }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<VerifySignatureResp>(resJson);
  }

  public static async createResetPasswordToken(email: string) {
    const url = `${Uris.Api.Auth.CreateResetPasswordToken}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string>(resJson);
  }

  public static async resetPassword(token: string, password: string) {
    const url = `${Uris.Api.Auth.ResetPassword}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token, password }),
    });
    const resJson = await ClientAPI.checkFetchResponse(res);
    return ClientAPI.extractResponse<string>(resJson);
  }
}

export default ClientAPI;
