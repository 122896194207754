import { FC, HTMLAttributes } from "react";

import { SnackbarProvider } from "notistack";
import classes from "./MessageProvider.module.scss";

export const MessageProvider: FC<HTMLAttributes<HTMLElement>> = (props) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      classes={{
        containerAnchorOriginTopCenter: classes.root,
        anchorOriginTopCenter: classes.snackbar,
      }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      preventDuplicate
      autoHideDuration={3000}
    >
      {props.children}
    </SnackbarProvider>
  );
};
