import { useAccount, useSignMessage } from 'wagmi';
import { useCallback, useContext, useEffect, useState } from 'react';

import ClientAPI from 'common/ClientAPI';
import { TrackingContext } from 'contexts/TrackingContext';
import { Uris } from 'Uris';
import { useMessage } from 'components/message/useMessage';
import { useTracking } from 'common/hooks/useTracking';

export const useWeb3Login = (onSuccess?: (status?: string, redirect_url?: string, provider?: string) => void) => {
  const { address } = useAccount();
  const { track } = useTracking();
  const { entryPoint$ } = useContext(TrackingContext);
  const { showMessage } = useMessage();
  const [provider, setProvider] = useState<string>('');
  const [chain, setChain] = useState<string>('');

  const verifySignature = useCallback(
    async (provider: string, chain: string, address: string, signature: string) => {
      await ClientAPI.verifyWeb3Signature(provider, chain, address, signature)
        .then(({ status, data }) => {
          if (status === 'success') {
            track(
              'click',
              {
                sub_event: 'login_completed',
                custom_props: {
                  entry_point: entryPoint$.getValue(),
                  authentication_method: provider,
                },
              },
              data?.access_token,
            );
            onSuccess?.(status, data?.redirect_url);
          } else if (status === 'redirect' && data?.account_id) {
            track('click', {
              sub_event: 'login_to_signup',
              custom_props: {
                entry_point: entryPoint$.getValue(),
                authentication_method: provider,
              },
            });
            onSuccess?.(status, `${Uris.Pages.Auth.RegisterConfirm.replace(':accountId', data?.account_id)}`, provider);
          } else {
            track('click', {
              sub_event: 'login_failed',
              custom_props: {
                entry_point: entryPoint$.getValue(),
                authentication_method: provider,
              },
            });
            showMessage(`Verify signature failed: ${status}`, 'error');
          }
        })
        .catch((error) => {
          track('click', {
            sub_event: 'login_failed',
            custom_props: {
              entry_point: entryPoint$.getValue(),
              authentication_method: provider,
            },
          });
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        });
    },
    [onSuccess, showMessage, track, entryPoint$],
  );

  const { error: signMessageError, signMessage } = useSignMessage({
    onSuccess(data) {
      if (!address || !provider || !chain) return;
      verifySignature(provider, chain, address, data);
    },
  });

  const signInWithAddress = useCallback(
    async (provider: string, chain: string, address: string) => {
      setProvider(provider);
      setChain(chain);
      await ClientAPI.signInWithAddress(provider, chain, address)
        .then(({ data: message }) => {
          if (!message) {
            showMessage('Unexpected error', 'error');
            return;
          }
          signMessage({ message });
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        });
    },
    [showMessage, signMessage],
  );

  useEffect(() => {
    if (signMessageError) {
      track('click', {
        sub_event: 'login_failed',
        custom_props: {
          entry_point: entryPoint$.getValue(),
          authentication_method: provider,
        },
      });
      showMessage(signMessageError.message, 'error');
    }
  }, [signMessageError, showMessage, track, provider, entryPoint$]);

  return { signInWithAddress };
};
