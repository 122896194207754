import { Box, BoxProps, Typography } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import { FC } from 'react';
import classNames from 'classnames';
import classes from './Spinner.module.scss';
import { colorPrimary40 } from 'common/params';

export const Spinner: FC<{ size?: number; text?: string; color?: string } & BoxProps> = ({
  size = 30,
  text,
  color = colorPrimary40,
  style = { height: '100%' },
  className,
  ...restProps
}) => {
  return (
    <Box className={classNames(classes.root, className)} {...restProps}>
      <CircularProgress size={size} />
      {text ? <Typography sx={{ color }}>{text}</Typography> : null}
    </Box>
  );
};
