import { useCallback, useContext } from 'react';

import { Config } from 'config';
import ReactGA from 'react-ga4';
import { TrackingContext } from 'contexts/TrackingContext';
import { jwtDecode } from 'jwt-decode';

const TRACKING_SERVER: string = window.location.origin.includes('localhost')
  ? 'https://pixel.dev223.growing3.ai'
  : window.location.origin.replace('accounts', 'pixel');

const CUSTOMER_ID: string = window.location.host === 'accounts.growing3.ai' ? 'dkzprh' : 'uszyrf';
const PROJECT_ID: string = window.location.host === 'accounts.growing3.ai' ? 'upmpaf' : 'wpdduw';

interface EventParams {
  sub_event?: string;
  custom_props?: object;
  event_props?: {
    link_classes?: string;
    link_domain?: string;
    link_id?: string;
    link_text?: string;
    link_url?: string;
    outbound?: string;

    form_id?: string;
    form_name?: string;
    form_destination?: string;
    form_submit_text?: string;

    method?: string;
    search_term?: string;
  };
}

const skip_ga_events = ['page_view'];
const sessionIdRefreshTime = 1000 * 60 * 30; // 30 mins

export const useTracking = () => {
  const { sessionId$, engageTime$, clientId$, pagePath$ } = useContext(TrackingContext);

  const track = useCallback(
    async (eventName: string, eventParams?: EventParams, accessToken?: string) => {
      // refresh sessionId if idle time larger than sessionIdRefreshTime
      const idleTime = new Date().getTime() - engageTime$.getValue().getTime();
      let sessionId = sessionId$.getValue();
      if (idleTime >= sessionIdRefreshTime) {
        sessionId = Math.floor(new Date().getTime() / 1000);
        sessionId$.next(sessionId);
      }

      const userProperties = accessToken ? jwtDecode(accessToken) : undefined;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const params: Record<string, any> = {
        ...eventParams,
        customer_id: CUSTOMER_ID,
        project_id: PROJECT_ID,
        client_id: clientId$.getValue(),
        user_properties: userProperties,
        app_version: Config.Version,
        session_id: sessionId$.getValue(),
        event_time: new Date().getTime(),
        event: eventName,

        page_info: {
          page_title: document.title,
          page_location: window.location.origin + pagePath$.getValue().curr,
          page_referrer: window.location.origin + pagePath$.getValue().prev,
        },
      };
      // every time a event is sent, clear engageTime
      engageTime$.next(new Date());

      await fetch(`${TRACKING_SERVER}/behavior/collect${window.location.search}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });

      if (skip_ga_events.findIndex((event) => event === eventName) > -1) return;
      ReactGA.gtag('event', eventName, {
        ...eventParams?.event_props,
        debug_mode: true,
      });
    },
    [sessionId$, engageTime$, clientId$, pagePath$],
  );

  return { track };
};
