export const dateFormatter = new Intl.DateTimeFormat();
export const datetimeFormatter = new Intl.DateTimeFormat(undefined, {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
});
export const numberFormatter = new Intl.NumberFormat();
export const priceFormatter = new Intl.NumberFormat(undefined, { minimumFractionDigits: 2 });
export const addressFormatter = (address: string) => {
  if (address.length <= 10) return address;
  else return `${address.substring(0, 5)}...${address.substring(address.length - 5)}`;
};
