import { useContext, useEffect } from 'react';

import ReactGA from 'react-ga4';
import { TrackingContext } from 'contexts/TrackingContext';
import { getUrlTitle } from 'Uris';
import { setCookie } from 'common/utils';
import { useLocation } from 'react-router-dom';
import { useObservable } from 'react-use';
import { useTracking } from './useTracking';

export const useTrackingInit = () => {
  const { pathname } = useLocation();

  const { pagePath$, clientId$ } = useContext(TrackingContext);
  const pagePath = useObservable(pagePath$);
  const { track } = useTracking();

  useEffect(() => {
    ReactGA.gtag('set', 'user_properties', {
      client_id: clientId$.getValue(),
    });
    setCookie('growing3_client_id', clientId$.getValue());
  }, [clientId$]);

  useEffect(() => {
    const pagePath = pagePath$.getValue();
    if (pathname === pagePath.curr) return;
    // one pathname changed -> change title and pagePath
    document.title = getUrlTitle(pathname) || 'Growing3';
    pagePath$.next({ prev: pagePath.curr, curr: pathname });
  }, [pathname, pagePath$]);

  useEffect(() => {
    if (!pagePath) return;
    if (pagePath.curr === pagePath.prev) return;
    // track page_view event when pagePath changed
    track('page_view');
  }, [track, pagePath]);

  return;
};
