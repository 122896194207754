import { SvgIcon, SvgIconProps } from '@mui/material';

export const MetaMaskIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 212 189'>
    <g fill='none' fillRule='evenodd'>
      <path fill='#CDBDB2' d='m60.8 173.3 27.5 7.3V171l2.3-2.2h15.7v19.1H89.4l-20.8-9z' />
      <path fill='#CDBDB2' d='m150.7 173.3-27 7.3V171l-2.2-2.2h-15.8v19.1h16.9l20.8-9z' />
      <path fill='#393939' d='M90.6 152.4 88.3 171l2.8-2.2h29.3l3.4 2.2-2.3-18.6-4.5-2.8-22.5.6z' />
      <path fill='#F89C35' d='m75.4 27 13.5 31.5 6.2 91.7H117l6.8-91.7L136.1 27z' />
      <path fill='#F89D35' d='M16.3 96.2.6 141.8l39.3-2.3h25.4v-19.7l-1.2-40.5-5.6 4.5z' />
      <path fill='#D87C30' d='m46.1 101.3 46.2 1.1-5.1 23.6-21.9-5.6z' />
      <path fill='#EA8D3A' d='m46.1 101.8 19.2 18v18z' />
      <path fill='#F89D35' d='m65.3 120.4 22.5 5.6 7.3 24.2L90 153l-24.7-14.6z' />
      <path fill='#EB8F35' d='m65.3 138.4-4.5 34.9 29.8-20.9z' />
      <path fill='#EA8E3A' d='m92.3 102.4 2.8 47.8-8.5-24.5z' />
      <path fill='#D87C30' d='m39.4 138.9 25.9-.5-4.5 34.9z' />
      <path fill='#EB8F35' d='m12.9 188.4 47.9-15.1-21.4-34.4-38.8 2.9z' />
      <path fill='#E8821E' d='M88.9 58.5 64.7 78.8l-18.6 22.5 46.2 1.6z' />
      <path fill='#DFCEC3' d='m60.8 173.3 29.8-20.9-2.3 18v10.2l-20.2-4zM150.8 173.3l-29.4-20.9 2.3 18v10.2l20.2-4z' />
      <path fill='#393939' d='m79.9 112.5 6.2 12.9-22-5.6z' />
      <path fill='#E88F35' d='m12.4.6 76.5 57.9-13-31.5z' />
      <path
        fill='#8E5A30'
        d='M12.4.6 2.3 31.5l5.6 33.8-4 2.2 5.7 5-4.5 4 6.2 5.6-4 3.4 9 11.3 42.2-13c20.6-16.5 30.8-25 30.4-25.3L12.4.5Z'
      />
      <path fill='#F89D35' d='m195.2 96.2 15.7 45.6-39.3-2.3h-25.4v-19.7l1.2-40.5 5.6 4.5z' />
      <path fill='#D87C30' d='m165.4 101.3-46.2 1.1 5.1 23.6 21.9-5.6z' />
      <path fill='#EA8D3A' d='m165.4 101.8-19.2 18v18z' />
      <path fill='#F89D35' d='m146.2 120.4-22.5 5.6-7.3 24.2 5.1 2.8 24.7-14.6z' />
      <path fill='#EB8F35' d='m146.2 138.4 4.5 34.9-29.2-20.3z' />
      <path fill='#EA8E3A' d='m119.2 102.4-2.8 47.8 8.5-24.5z' />
      <path fill='#D87C30' d='m172.1 138.9-25.9-.5 4.5 34.9z' />
      <path fill='#EB8F35' d='m198.6 188.4-47.9-15.1 21.4-34.4 38.8 2.9z' />
      <path fill='#E8821E' d='m122.6 58.5 24.2 20.3 18.6 22.5-46.2 1.6z' />
      <path fill='#393939' d='m131.6 112.5-6.2 12.9 22-5.6z' />
      <path fill='#E88F35' d='m199.1.6-76.5 57.9 13-31.5z' />
      <path
        fill='#8E5A30'
        d='m199.1.6 10.1 30.9-5.6 33.8 4 2.2-5.7 5 4.5 4-6.2 5.6 4 3.4-9 11.3-42.2-13a369.4 369.4 0 0 1-30.4-25.3l76.5-58Z'
      />
    </g>
  </SvgIcon>
);
