import { Button, FormControl, FormHelperText, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CheckOutlined } from '@mui/icons-material';
import ClientAPI from 'common/ClientAPI';
import { CommonChip } from 'components/chip/CommonChip';
import { CommonDialog } from 'components/dialog/CommonDialog';
import { TrackingContext } from 'contexts/TrackingContext';
import { Uris } from 'Uris';
import classes from './ForgetPasswordPage.module.scss';
import { colorPrimary10 } from 'common/params';
import { useTracking } from 'common/hooks/useTracking';
import { validateEmail } from 'common/utils';

export const ForgetPasswordPage: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { track } = useTracking();
  const { entryPoint$ } = useContext(TrackingContext);

  const [email, setEmail] = useState<string>(state?.email?.toLowerCase() || '');
  const [successDialogOpened, setSuccessDialogOpened] = useState<boolean>(false);

  const [emailError, setEmailError] = useState<boolean>(false);
  const [backendError, setBackendError] = useState<string>('');

  const onSubmitBtnClick = useCallback(async () => {
    const emailError = !validateEmail(email);
    setEmailError(emailError);
    if (emailError) return;

    await ClientAPI.createResetPasswordToken(email)
      .then(({ status }) => {
        if (status === 'success') {
          setSuccessDialogOpened(true);
          track('click', {
            sub_event: 'password_reset_sent',
            custom_props: {
              entry_point: entryPoint$.getValue(),
              email: email,
            },
          });
        } else {
          setBackendError('Unexpected error');
        }
      })
      .catch((error) => {
        setBackendError(error instanceof Error ? error.message : 'Unknown Error');
      });
  }, [email, track, entryPoint$]);

  const onDialogClosed = useCallback(() => {
    setSuccessDialogOpened(false);
  }, []);

  const submitBtnDisabled = useMemo(() => {
    return !email;
  }, [email]);

  return (
    <Stack direction='row' className={classes.root}>
      <Stack className={classes.imgContainer}>
        <img alt='login' src={Uris.Public.Image.Auth.ForgetPassword} className={classes.img} />
      </Stack>
      <Stack className={classes.block}>
        <Stack className={classes.container} spacing={3}>
          <Stack direction='row' justifyContent='center'>
            <img alt='logo' className={classes.logo} src={Uris.Public.Logo.NoSub.Dark} />
          </Stack>
          <Typography variant='h4' color={colorPrimary10}>
            Forget your password?
          </Typography>
          <Stack spacing={2}>
            <Typography variant='body1'>
              Type in the email or username associated with your account and we'll send you an email to reset it.
            </Typography>
            <FormControl>
              <InputLabel error={!!emailError}>email address</InputLabel>
              <OutlinedInput
                size='medium'
                label='email address'
                error={!!emailError}
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
              />
            </FormControl>
            <FormHelperText>
              If the email doesn't show up soon, check your spam folder. We sent it from no-reply@growing3.ai
            </FormHelperText>
            {emailError ? (
              <Stack style={{ maxWidth: '360px' }}>
                <FormHelperText error>Invalid email format</FormHelperText>
              </Stack>
            ) : null}
          </Stack>
          {backendError ? <Typography color='error'>{backendError}</Typography> : null}
          <Stack direction='row-reverse' spacing={2}>
            <Button variant='contained' onClick={onSubmitBtnClick} disabled={submitBtnDisabled}>
              Send email
            </Button>
            <Button
              variant='outlined'
              onClick={() => {
                navigate(-1);
              }}
            >
              Back to sign in
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {/* send success dialog */}
      <CommonDialog
        open={successDialogOpened}
        mainContent={
          <Stack spacing={2} alignItems='center' className={classes.dialog}>
            <CommonChip>
              <CheckOutlined fontSize='large' />
            </CommonChip>
            <Stack spacing={1} className={classes.title}>
              <Typography variant='h6'>Password reset link sent!</Typography>
              <Stack>
                <Typography variant='body1'>Please check your inbox. We sent it from</Typography>
                <Typography variant='body1'>no-reply@growing3.ai</Typography>
              </Stack>
            </Stack>
          </Stack>
        }
        footer={<></>}
        onDialogClose={onDialogClosed}
      />
    </Stack>
  );
};
