import { FC, HTMLAttributes } from 'react';

import ReactGA from 'react-ga4';

const REACT_APP_GA_ID: string = window.location.host === 'accounts.growing3.ai' ? 'G-RN6867XQZE' : 'G-KGMY9EV5Z7';

// Google Analytics
ReactGA.initialize(REACT_APP_GA_ID);

export const TrackingProvider: FC<HTMLAttributes<HTMLElement>> = (props) => {
  return <>{props.children}</>;
};
