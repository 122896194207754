import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { CheckOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { FC, useCallback, useContext, useState } from 'react';
import { colorPrimary10, passwordRules } from 'common/params';
import { useNavigate, useParams } from 'react-router-dom';

import ClientAPI from 'common/ClientAPI';
import { CommonChip } from 'components/chip/CommonChip';
import { CommonDialog } from 'components/dialog/CommonDialog';
import { TrackingContext } from 'contexts/TrackingContext';
import { Uris } from 'Uris';
import classes from './ResetPasswordPage.module.scss';
import { useTracking } from 'common/hooks/useTracking';
import { validatePassword } from 'common/utils';

export const ResetPasswordPage: FC = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { track } = useTracking();
  const { entryPoint$ } = useContext(TrackingContext);

  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [successDialogOpened, setSuccessDialogOpened] = useState<boolean>(false);

  const [newPasswordError, setNewPasswordError] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');
  const [backendError, setBackendError] = useState<string>('');

  const onShowNewPasswordBtnClicked = () => setShowNewPassword((show) => !show);
  const onShowConfirmPasswordBtnClicked = () => setShowConfirmPassword((show) => !show);

  const onSubmitBtnClick = useCallback(async () => {
    if (!token) return;
    const newPasswordError = !validatePassword(newPassword);
    const confirmPasswordError = newPassword !== confirmPassword;
    setNewPasswordError(newPasswordError ? 'Password does not match the format' : '');
    setConfirmPasswordError(confirmPasswordError ? 'Confirm password not match' : '');
    if (newPasswordError || confirmPasswordError) return;

    await ClientAPI.resetPassword(token, newPassword)
      .then(({ status }) => {
        if (status === 'success') {
          setSuccessDialogOpened(true);
          track('click', {
            sub_event: 'password_reset_completed',
            custom_props: {
              entry_point: entryPoint$.getValue(),
              email: 'email',
            },
          });
        } else {
          setBackendError('Unexpected error');
        }
      })
      .catch((error) => {
        setBackendError(error instanceof Error ? error.message : 'Unknown Error');
      });
  }, [token, newPassword, confirmPassword, track, entryPoint$]);

  const onDialogClosed = useCallback(() => {
    navigate(Uris.Pages.Auth.SignIn);
  }, [navigate]);

  return (
    <Stack direction='row' className={classes.root}>
      <Stack className={classes.imgContainer}>
        <img alt='login' src={Uris.Public.Image.Auth.ForgetPassword} className={classes.img} />
      </Stack>
      <Stack className={classes.block}>
        <Stack className={classes.container} spacing={3}>
          <Stack direction='row' justifyContent='center'>
            <img alt='logo' className={classes.logo} src={Uris.Public.Logo.NoSub.Dark} />
          </Stack>
          <Typography variant='h4' color={colorPrimary10}>
            Reset the password!
          </Typography>
          <Stack spacing={2}>
            <FormControl>
              <InputLabel error={!!newPasswordError}>New password</InputLabel>
              <OutlinedInput
                size='medium'
                label='New password'
                error={!!newPasswordError}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                type={showNewPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={onShowNewPasswordBtnClicked}
                      onMouseDown={onShowNewPasswordBtnClicked}
                      edge='end'
                    >
                      {showNewPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText error={!!newPasswordError}>
                {newPasswordError ? (
                  <Typography component='span' variant='body2'>
                    {newPasswordError}
                    <br />
                  </Typography>
                ) : null}
                {passwordRules.map((rule, idx) => (
                  <Typography component='span' key={idx} variant='body2'>
                    {idx > 0 ? <br /> : null}
                    {rule}
                  </Typography>
                ))}
              </FormHelperText>
            </FormControl>
            <FormControl>
              <InputLabel error={!!confirmPasswordError || !!newPasswordError}>Confirm password</InputLabel>
              <OutlinedInput
                size='medium'
                label='Confirm password'
                error={!!confirmPasswordError || !!newPasswordError}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={showConfirmPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={onShowConfirmPasswordBtnClicked}
                      onMouseDown={onShowConfirmPasswordBtnClicked}
                      edge='end'
                    >
                      {showConfirmPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {confirmPasswordError || newPasswordError ? (
                <FormHelperText error={true}>
                  {confirmPasswordError ? confirmPasswordError : newPasswordError}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Stack>
          {backendError ? <Typography color='error'>{backendError}</Typography> : null}
          <Stack direction='row-reverse' spacing={1} className={classes.btn}>
            <Button variant='contained' onClick={onSubmitBtnClick}>
              Reset Password
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {/* reset success dialog */}
      <CommonDialog
        open={successDialogOpened}
        mainContent={
          <Stack spacing={3} alignItems='center' className={classes.dialog}>
            <CommonChip>
              <CheckOutlined fontSize='large' />
            </CommonChip>
            <Typography variant='h6' className={classes.title}>
              Your password has been reset successfully
            </Typography>
          </Stack>
        }
        footer={
          <Button variant='contained' onClick={onDialogClosed}>
            Login now
          </Button>
        }
        onDialogClose={onDialogClosed}
      />
    </Stack>
  );
};
