import { MetaMaskIcon } from 'components/icons/MetaMaskIcon';
import { MonetizationOnOutlined } from '@mui/icons-material';

export const getWeb3IconByConnector = (connector: string) => {
  switch (connector) {
    case 'MetaMask':
      return <MetaMaskIcon />;
  }
  return <MonetizationOnOutlined />;
};
