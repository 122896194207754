import { SvgIcon, SvgIconProps } from '@mui/material';

export const GoogleIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 48 48'>
    <path fill='#FFC107' d='M43.6 20H24v8h11.3A12 12 0 1 1 32 15l5.6-5.6A20 20 0 0 0 4 24a20 20 0 1 0 39.6-4z' />
    <path fill='#FF3D00' d='m6.3 14.7 6.6 4.8a12 12 0 0 1 19-4.5l5.7-5.6a20 20 0 0 0-31.3 5.3z' />
    <path fill='#4CAF50' d='M24 44c5.2 0 9.9-2 13.4-5.2l-6.2-5.2A12 12 0 0 1 12.7 28l-6.5 5A20 20 0 0 0 24 44z' />
    <path fill='#1976D2' d='M43.6 20H24v8h11.3a12 12 0 0 1-4 5.6l6.1 5.2C37 39.2 44 34 44 24c0-1.3-.1-2.6-.4-4z' />
  </SvgIcon>
);
