import { FC, useCallback, useEffect } from 'react';

import ClientAPI from 'common/ClientAPI';
import { Uris } from 'Uris';
import { getHomepageUrl } from 'common/utils';
import { useMessage } from 'components/message/useMessage';

export const LogoutPage: FC = () => {
  const { showMessage } = useMessage();
  const signOut = useCallback(async () => {
    await ClientAPI.signOut()
      .then(({ status, data }) => {
        if (status === 'success') {
          // has redirect url -> directly change
          if (data?.redirect_url) {
            window.location.replace(data?.redirect_url);
            return;
          }
          // redirect to homepage
          window.location.replace(getHomepageUrl(Uris.External.Home));
        } else {
          showMessage(status || '', 'error');
        }
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
      });
  }, [showMessage]);

  useEffect(() => {
    signOut();
  }, [signOut]);

  return <></>;
};
