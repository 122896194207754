import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { FC } from 'react';
import { ForgetPasswordPage } from 'pages/Auth/ForgetPasswordPage';
import { HomePage } from 'pages/HomePage';
import { LayoutProvider } from 'components/layout/LayoutProvider';
import { LoginPage } from 'pages/Auth/LoginPage';
import { LogoutPage } from './Auth/LogoutPage';
import { NotFoundPage } from 'pages/Common/NotFoundPage';
import { RegisterConfirmPage } from 'pages/Auth/RegisterConfirmPage';
import { ResetPasswordPage } from 'pages/Auth/ResetPasswordPage';
import { SignInUpPage } from 'pages/Auth/SignInUpPage';
import { ThemeProvider } from 'theme/ThemeProvider';
import { Uris } from 'Uris';

const router = createBrowserRouter(
  createRoutesFromElements(
    // shared layout
    <Route element={<LayoutProvider />}>
      <Route path={Uris.Pages.Root} element={<HomePage />}></Route>
      <Route path={Uris.Pages.Auth.Login} element={<LoginPage />}></Route>
      <Route path={Uris.Pages.Auth.Logout} element={<LogoutPage />}></Route>
      <Route path={Uris.Pages.Auth.SignIn} element={<SignInUpPage signIn />}></Route>
      <Route path={Uris.Pages.Auth.SignUp} element={<SignInUpPage signIn={false} />}></Route>
      <Route path={Uris.Pages.Auth.RegisterConfirm} element={<RegisterConfirmPage />}></Route>
      <Route path={Uris.Pages.Auth.ForgetPassword} element={<ForgetPasswordPage />}></Route>
      <Route path={Uris.Pages.Auth.ResetPassword} element={<ResetPasswordPage />}></Route>

      <Route path='*' element={<NotFoundPage />} />
    </Route>,
  ),
);

export const MainPage: FC = () => {
  return (
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};
