import { Button, FormControl, FormHelperText, InputLabel, Link, OutlinedInput, Stack, Typography } from '@mui/material';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getHomepageUrl, validateEmail } from 'common/utils';
import { useLocation, useParams } from 'react-router';

import { CheckOutlined } from '@mui/icons-material';
import ClientAPI from 'common/ClientAPI';
import { CommonChip } from 'components/chip/CommonChip';
import { CommonDialog } from 'components/dialog/CommonDialog';
import { TrackingContext } from 'contexts/TrackingContext';
import { Uris } from 'Uris';
import classes from './RegisterConfirmPage.module.scss';
import { colorPrimary10 } from 'common/params';
import { useTracking } from 'common/hooks/useTracking';

const coolDownSecs = 60; // cool down secs to re-send email

export const RegisterConfirmPage: FC = () => {
  const { state } = useLocation();
  const { accountId } = useParams();
  const { search } = useLocation();
  const { track } = useTracking();
  const { entryPoint$ } = useContext(TrackingContext);

  const [sending, setSending] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);
  const [coolDown, setCoolDown] = useState<number>(-1);
  const [email, setEmail] = useState<string>(state?.email?.toLowerCase() || '');
  const [confirmCode, setConfirmCode] = useState<string>('');
  const [successDialogOpened, setSuccessDialogOpened] = useState<boolean>(false);

  const [emailError, setEmailError] = useState<boolean>(false);
  const [confirmCodeError, setConfirmCodeError] = useState<boolean>(false);
  const [backendError, setBackendError] = useState<string>('');

  useEffect(() => {
    if (coolDown < 0) return;
    const timeout = setTimeout(() => {
      setCoolDown((old) => old - 1);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    const parameters = new URLSearchParams(search);
    const email = parameters.get('email');
    if (email) setEmail(email.toLowerCase());
  }, [search]);

  const onSendCodeBtnClick = useCallback(
    async (email: string) => {
      if (!accountId) return;
      const emailError = !validateEmail(email);
      setEmailError(emailError);
      if (emailError) return;

      setSent(true);
      setSending(true);
      await ClientAPI.register(accountId, email)
        .then(({ status }) => {
          if (status === 'success') {
            setCoolDown(coolDownSecs);
            setSuccessDialogOpened(true);
          } else {
            setBackendError('Confirm code sent failed');
          }
        })
        .catch((error) => {
          setBackendError(error instanceof Error ? error.message : 'Unknown Error');
        })
        .finally(() => {
          setSending(false);
        });
    },
    [accountId],
  );

  const onSubmitBtnClick = useCallback(async () => {
    if (!accountId) return;
    const confirmCodeError = confirmCode.length !== 6;
    setConfirmCodeError(confirmCodeError);
    if (confirmCodeError) return;

    await ClientAPI.registerConfirm(accountId, confirmCode)
      .then(async ({ status, data }) => {
        if (status === 'success') {
          await track(
            'click',
            {
              sub_event: 'register_completed',
              custom_props: {
                entry_point: entryPoint$.getValue(),
                authentication_method: state?.authentication_method,
              },
            },
            data?.access_token,
          ).finally(() => {
            if (data?.redirect_url) {
              window.location.replace(data?.redirect_url);
              return;
            }
            // redirect to homepage
            window.location.replace(getHomepageUrl(Uris.External.Home));
          });
        } else {
          setBackendError('Unexpected error');
        }
      })
      .catch((error) => {
        setBackendError(error instanceof Error ? error.message : 'Unknown Error');
      });
  }, [accountId, confirmCode, state?.authentication_method, track, entryPoint$]);

  const onDialogClosed = useCallback(() => {
    setSuccessDialogOpened(false);
  }, []);

  const emailFieldDisabled = useMemo(() => {
    return !!state?.email;
  }, [state]);

  useEffect(() => {
    if (!state?.email) return;
    if (!accountId) return;
    const timeout = setTimeout(() => {
      onSendCodeBtnClick(state?.email?.toLowerCase());
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [state?.email, accountId, onSendCodeBtnClick]);

  const sendCodeBtnDisabled = useMemo(() => {
    return sending || coolDown >= 0;
  }, [sending, coolDown]);

  const sendBtnMsg = useMemo(() => {
    if (!sent) return 'Send Code';
    return `Resend Code${coolDown >= 0 ? ` (${coolDown} s)` : ''}`;
  }, [sent, coolDown]);

  return (
    <Stack direction='row' className={classes.root}>
      <Stack className={classes.imgContainer}>
        <img alt='login' src={Uris.Public.Image.Auth.Promo} className={classes.img} />
      </Stack>
      <Stack className={classes.block}>
        <Stack className={classes.container} spacing={3}>
          <Stack direction='row' justifyContent='center'>
            <img alt='logo' className={classes.logo} src={Uris.Public.Logo.NoSub.Dark} />
          </Stack>
          <Typography variant='h4' color={colorPrimary10}>
            Please verify email
          </Typography>
          <Stack spacing={2}>
            <FormControl>
              <InputLabel error={!!emailError}>Your email address</InputLabel>
              <OutlinedInput
                size='medium'
                label={'Your email address'}
                error={!!emailError}
                disabled={emailFieldDisabled}
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
              />
              {emailError ? (
                <Stack style={{ maxWidth: '360px' }}>
                  <FormHelperText error>Invalid email format</FormHelperText>
                </Stack>
              ) : null}
            </FormControl>
            <FormControl>
              <InputLabel error={!!confirmCodeError}>Verification Code</InputLabel>
              <OutlinedInput
                type='number'
                size='medium'
                label={'Verification Code'}
                error={!!confirmCodeError}
                value={confirmCode}
                onChange={(e) => e.target.value.length <= 6 && setConfirmCode(e.target.value)}
                sx={{
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                  '& input[type=number]::-webkit-outer-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                  },
                  '& input[type=number]::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                  },
                }}
              />
            </FormControl>
            {confirmCodeError ? (
              <FormHelperText error={true}>
                Invalid format: Enter the 6-digit code without spaces or dashes
              </FormHelperText>
            ) : null}
          </Stack>
          {backendError ? <Typography color='error'>{backendError}</Typography> : null}
          <Stack direction='row-reverse' spacing={1}>
            <Button variant='contained' onClick={onSubmitBtnClick}>
              Submit Code
            </Button>
            <Button
              id='send-code-btn'
              variant='contained'
              onClick={() => onSendCodeBtnClick(email)}
              disabled={sendCodeBtnDisabled}
            >
              {sendBtnMsg}
            </Button>
          </Stack>
          <Typography variant='body1' className={classes.note}>
            Still having trouble with the login process?
            <br />
            <Link variant='body1' href={Uris.Pages.Root}>
              Contact us
            </Link>
          </Typography>
          <Typography variant='body2' className={classes.note}>
            <Link variant='body2' href={getHomepageUrl(Uris.External.TermsOfService)} target='_blank'>
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link variant='body2' href={getHomepageUrl(Uris.External.PrivacyPolicy)} target='_blank'>
              Privacy Policy
            </Link>
          </Typography>
        </Stack>
      </Stack>
      {/* send success dialog */}
      <CommonDialog
        open={successDialogOpened}
        mainContent={
          <Stack spacing={2} alignItems='center' className={classes.dialog}>
            <CommonChip>
              <CheckOutlined fontSize='large' />
            </CommonChip>
            <Typography variant='h6' className={classes.title}>
              Confirm code has been sent to your email.
            </Typography>
          </Stack>
        }
        footer={
          <Button variant='contained' onClick={onDialogClosed}>
            Close
          </Button>
        }
        onDialogClose={onDialogClosed}
      />
    </Stack>
  );
};
